<template>
  <!-- 问题组件 -->
  <div class="question-container" :style="content.style || {}">
    <div class="question-main">
      <component :is="content.tag || 'h2'" class="title">{{ content.title }}</component>
      <div class="question-list">
        <div v-for="(item, idx) in content.list" :key="idx" class="question-item">
          <div :class="['question-top', activeIdx === idx && 'active']" @click="checkedActive(idx)">
            <div
              class="item-title"
              :style="{ color: activeIdx === idx? '#419EFF' : '' }"
            >
              {{ item.title }}
            </div>
            <div class="item-icon">
              <img
                class="pc-icon"
                :style="{ transform: activeIdx === idx? 'rotate(-0)' : 'rotate(-135deg)' }"
                :src="$getFile('questions/question-close-icon.svg')"
                alt=""
              >
              <!-- <img class="mobile-icon" :style="{ transform: activeIdx === idx? 'rotate(0)' : 'rotate(-180deg)' }" src="~/static/images/question-top.webp"> -->
              <img class="mobile-icon" :style="{ transform: activeIdx === idx? 'rotate(0)' : 'rotate(-135deg)' }" :src="$getFile('questions/question-close-icon.svg')" alt="">
            </div>
          </div>
          <template v-for="(des, dIdx) in item.desList">
            <div ref="default" :key="'d'+dIdx" :class="[ 'default-des' ]">
              <div ref="question" :class="['question-des', activeIdx === idx && 'question-active' ]">
                <span v-html="des.des" />
                <ul>
                  <li v-for="(l, lIdx) in des.list" :key="lIdx" v-html="l.des">
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionsList',
  props: {
    content: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      activeIdx: '',
      showMobileHeader: false
    }
  },
  onLoad() {
  },
  methods: {
    checkedActive(idx) {
      this.activeIdx = this.activeIdx === idx? '' : idx // 当前选中节点与idx相同，则收起。否则选中idx
      this.$refs.default.forEach(el => {
        el.style.height = el.style.paddingBottom = 0
      })
      if (this.activeIdx === idx) {
        const activeEl = this.$refs.default[idx]
        activeEl.style.height = this.$refs.question[idx].offsetHeight + 'px'
        activeEl.style.paddingBottom = '20px'
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @media (min-width: 981px) {
    .question-container {
      .question-main {
        width: 90%;
        max-width: 1080px;
        padding: 140px 0 108px 0;
        margin: 0 auto;
        .title {
          text-align: center;
          font-size: 36px;
          font-family: Poppins;
          color: var(--theme-font-normal);
          line-height: 52px;
          margin-top: 0;
          margin-bottom: 48px;
        }
        .question-list {
          .question-item {
            &:not(:last-child) {
              border-bottom: 1px solid #EDEDED;
            }
            .question-top {
              display: flex;
              justify-content: space-between;
              padding: 32px 0;
              line-height: 52px;
              cursor: pointer;
              transition: all 200ms;
              &:hover {
                .item-title {
                  color: #419EFF;
                }
              }
              .item-title {
                font-size: 20px;
                font-family: Poppins-Medium, Poppins;
                font-weight: 500;
                color: var(--theme-font-normal);
                line-height: 32px;
              }
              .item-icon {
                width: auto;
                height: auto;
                text-align: center;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  //background: #E9E9E9;
                }
                .pc-icon {
                  width: 16px;
                  height: 16px;
                  transition: all 200ms;
                }
                .mobile-icon {
                  display: none;
                  width: 16px;
                  height: 16px;
                  transition: all 200ms;
                }
              }

            }
            .active {
              padding-bottom: 12px;
              .item-title {
                color: #419EFF;
              }
            }
            .default-des {
              height: 0;
              overflow: hidden;
              transition: all 0.25s ease-out;
              .question-des {
                font-size: 16px;
                font-family: Poppins-Regular, Poppins;
                font-weight: 400;
                color: #36354E;
                line-height: 24px;
                display: block;
                box-sizing: border-box;
                opacity: 0;
                transition: all 0.25s ease-out;
              }
              .question-active {
                opacity: 1 !important;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 980px) {
    .question-container {
      -webkit-tap-highlight-color: transparent;
      .question-main {
        padding: 1.2rem 0.31rem;
        .title {
          font-size: 0.48rem;
          font-family: Poppins;
          color: var( --theme-font-normal);
          line-height: 0.72rem;
          text-align: center;
          margin-bottom: .12rem;
        }
        .question-list {
          .question-item {
            &:not(:last-child) {
              border-bottom: 1px solid #EDEDED;
            }
            .question-top {
              display: flex;
              justify-content: space-between;
              padding: 0.46rem 0 0;
              line-height: .64rem;
              cursor: pointer;
              transition: all 200ms;
              &.active {
                color: #419EFF;
              }
              .item-title {
                color: #05021E;
                font-size: 0.32rem;
                line-height: 0.48rem;
                font-family: Poppins-Medium, Poppins;
                font-weight: 500;
              }
              .item-icon {
                width: 0.32rem;
                height: 0.32rem;
                padding-top: 0.1rem;
                flex: none;
                margin-left: 0.32rem;
                display: flex;
                align-items: center;
                .pc-icon {
                  display: none;
                }
                .mobile-icon {
                  width: 100%;
                  height: 100%;
                  transition: all 200ms;
                }
              }
            }
            .default-des {
              height: 0;
              overflow: hidden;
              margin-top: 0.48rem;
              transition: all 0.25s ease-out;
              .question-des {
                color: #36354E;
                font-size: 0.28rem;
                line-height: 0.42rem;
                font-family: Poppins-Regular, Poppins;
                display: block;
                box-sizing: border-box;
                opacity: 0;
                transition: all 0.25s ease-out;
              }
              .question-active {
                opacity: 1 !important;
              }
            }
          }
        }
      }
    }
  }
</style>
